<template>
  <div>
    <v-row>
      <v-col sm="4" md="3" lg="2"
        ><v-card class="mx-auto" width="256" tile>
          <v-navigation-drawer permanent>
            <v-system-bar></v-system-bar>

            <v-divider name="divider"></v-divider>
            <v-list nav dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  link
                  :to="item.href"
                  active-class="border"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer> </v-card
      ></v-col>
      <v-col sm="8" md="9" lg="10"><router-view></router-view></v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  components: {},
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "组织用户", icon: "mdi-folder", href: "/organ/list" },
      {
        text: "分配资产",
        icon: "mdi-account-check",
        href: "/organ/allocate",
      },
    ],
  }),
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.border {
  border-left: 4px solid #0ba518;
}
</style>
